import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ExerciseGenerator from './exercise_generator/ExerciseGenerator';
import StoryGenerator from './story_generator/StoryGenerator';
import logo from '../assets/large-logo.png';

const MainLayout = () => {
  return (
    <div className="flex-grow max-w-5xl mx-auto">
      <header className="mb-12 text-center">
        <div className="flex items-center justify-center mb-4">
          <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-extrabold text-indigo-600">
            WonderMind.Org
          </h1>
        </div>
        <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800 mb-2">
          For Curious Children
        </h2>
      </header>

      <Tabs selectedTabClassName="border-b-4 border-indigo-600 text-indigo-700">
        <TabList className="flex mb-8">
          {/* <Tab className="flex-1 px-8 py-4 text-center text-lg font-semibold focus:outline-none cursor-pointer transition-all duration-300 bg-white rounded-t-lg shadow-md hover:bg-indigo-50">
            Story Generator
          </Tab> */}
          <Tab className="flex-1 px-8 py-4 text-center text-lg font-semibold focus:outline-none cursor-pointer transition-all duration-300 bg-white rounded-t-lg shadow-md hover:bg-indigo-50">
            Activity Ideas
          </Tab>
        </TabList>

        <div className="bg-white rounded-lg shadow-lg p-8">
          {/* <TabPanel>
            <StoryGenerator />
          </TabPanel> */}
          <TabPanel>
            <ExerciseGenerator />
          </TabPanel>
        </div>
      </Tabs>
    </div>
  );
};

export default MainLayout;